/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CustomTitle, SeeAllStyledModal, PopupDetials } from './styles';
import { Modal } from 'react-bootstrap';

class CreateChallengePoup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showChallenge: false
    }
  }

  onClosePopup = () => {
    this.setState({
      showChallenge: false
    })
  };

  render() {
    const { showChallenge } = this.state;
    return (
      <SeeAllStyledModal
        show={showChallenge}
        dialogClassName="modal-90w"
      >
        <Modal.Header >
          <CustomTitle>
            <div>
              <span>Create Challenge</span>
              <span className="closeImage" onClick={() => this.onClosePopup()}><img src='/public/images/new_close_icon.png' /></span>
            </div>
          </CustomTitle>
        </Modal.Header>
        <Modal.Body>
          <PopupDetials>
            <div className="congrats">Congrats!</div>
            <div className="congratssub">On creating your wellness challenge</div>
            <div className="challengeName">Challenge Name </div>
            <div className="challengeIcon">
              <img src={"/public/images/CompanyDashBoardV2/toolTipIcon.png"} />
            </div>
            <div className="cardWrapper">
              <div className="categoryType">
                <div className="categoryImage">
                  <img src={"/public/images/CompanyDashBoardV2/toolTipIcon.png"} />
                </div>
                <div className="categoryName">category</div>
              </div>
              <div className="question">
                <div className="text"> question 1 level 1</div>
              </div>
            </div>
          </PopupDetials>
        </Modal.Body>
      </SeeAllStyledModal>
    );
  }
}

CreateChallengePoup.propTypes = {
  history: PropTypes.object
};

export default (CreateChallengePoup);
