/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Main } from '../../AdminTeamCreation/styles';
import { ChallengeHeading, Container, InputContainer, StyledInputV2, StyledLabel, UploadPhotoContainer, RemoveImage, InputImage, CustomTextArea, ButtonContainer, Button } from './styles';
import StepsHeading from './stepsName';
import { getOrientation, resetOrientation, checkImage } from '../../../utils/methods';
import { toast } from 'react-toastify';
import { imageErrorMessage } from '../../../utils/constants';
import { connect } from 'react-redux';
import { saveChallengeInformation, getChallengeInformation } from '../../../redux/actions';

class CreateChallengeFirst extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 1,
      step: 1,
      imgSrc: null,
      description: '',
      title: '',
      imageName: '',
      videoTitle: '',
      introductionVideo: ''
    }
  }

  componentDidMount() {
    const { getChallengeInformation, challengeData } = this.props;
    getChallengeInformation();
    this.setState({
      title: challengeData.title,
      videoTitle: challengeData.videoTitle,
      introductionVideo: challengeData.introductionVideo,
      description: challengeData.description,
      imgSrc: challengeData.imgSrc
    });
  }

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.props.saveChallengeInformation(e.target.name, e.target.value)
  };

  onChangeImg = (e) => {
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      const array = document.getElementById('behavior-challenge-file').value.split("\\");
      reader.onloadend = () => {
        this.setState({
          imgSrc: reader.result,
          imageName: array[array.length - 1],
        });
        getOrientation(file, (or) => {
          resetOrientation([reader.result], or, (baseImage) => {
            this.props.saveChallengeInformation("imgSrc", baseImage)
            this.setState({
              imgSrc: baseImage
            });
          });
        });
      };
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('behavior-challenge-file').value = '';
    }
  };

  removePhoto = () => {
    this.setState({
      imgSrc: null,
      imageName: '',
    });
  };

  render() {
    const { step, description, title, videoTitle, introductionVideo, imgSrc } = this.state;
    const { history } = this.props;
    return (
      <React.Fragment>
        <Layout marginBottom="0px">
          <Main>
            <ChallengeHeading>
              Create a Wellness Challenge
            </ChallengeHeading>
            <Container>
              <div className="stepsNameHighlight">
                <StepsHeading stepCount={step} />
              </div>
              <div className="form">
                <div className="heading">
                  <div className="step">1</div>
                  <div className="headingName">Introduce about the challenge to your employees</div>
                </div>
                <div className="formBody">
                  <InputContainer>
                    <StyledLabel>Title Of the Challenge{<span>*</span>}</StyledLabel>
                    <StyledInputV2
                      type={"text"}
                      placeholder={"Enter Name of the Challenge"}
                      name={"title"}
                      onChange={this.onChangeInput}
                      value={title}
                      maxLength={100}
                    />
                  </InputContainer>

                  <StyledLabel>Challenge Image{<span>*</span>}</StyledLabel>
                  <UploadPhotoContainer>
                    <div className='innerDivImageContainer'>
                      <img alt='profilre-img' src={this.state.imgSrc ? this.state.imgSrc : "/images/default.png"} ></img>
                      {this.state.imgSrc == null ? <InputImage className="edit-profile-pic " imageReload={1}>
                        <i className="addImg">{"Upload Image"}</i>
                        <input
                          id="behavior-challenge-file"
                          type="file"
                          name="user-profile"
                          multiple={false}
                          accept=".jpeg, .png, .jpg"
                          onChange={(e) => this.onChangeImg(e)}
                          onClick={(e) => e.target.files[0] && this.onChangeImg(e)}
                        />
                      </InputImage> :
                        <RemoveImage onClick={this.removePhoto}>{"Remove Image"}</RemoveImage>}
                    </div>
                  </UploadPhotoContainer>
                  <InputContainer>
                    <StyledLabel>About The Challenge{<span>*</span>}</StyledLabel>
                    <CustomTextArea
                      placeholder="Write Descirption about the Challenge"
                      rows="8"
                      name="description"
                      value={description}
                      onChange={this.onChangeInput}
                      data-gramm_editor="false"
                      type="text"
                    />
                  </InputContainer>
                  <InputContainer>
                    <StyledLabel>Intro Video Title</StyledLabel>
                    <StyledInputV2
                      type={"text"}
                      placeholder={"Enter Video Title"}
                      name={"videoTitle"}
                      value={videoTitle}
                      onChange={this.onChangeInput}
                    />
                  </InputContainer>
                  <InputContainer>
                    <StyledLabel>Introduction video Link</StyledLabel>
                    <StyledInputV2
                      type="url"
                      pattern="https?://.+"
                      placeholder={"Paste Here your intro Video link"}
                      name={"introductionVideo"}
                      onChange={this.onChangeInput}
                      value={introductionVideo}
                    />
                  </InputContainer>
                </div>
              </div>
            </Container>
          </Main>
        </Layout>
        <ButtonContainer>
          <Main>
            <Button  marginAuto="1" disabled={!title || !imgSrc || !description} onClick={() => history.push('/company/challenges/create/step2')}>
              {"Next >>"}
            </Button>
          </Main>
        </ButtonContainer>
      </React.Fragment>
    );
  }
}

CreateChallengeFirst.propTypes = {
  history: PropTypes.object,
  challengeData: PropTypes.object,
  saveChallengeInformation: PropTypes.func,
  getChallengeInformation: PropTypes.func
};

const mapStateToProps = (state) => ({
  challengeData: state.challengesAdmin.challengeData
});

const mapDispatchToProps = (dispatch) => ({
  saveChallengeInformation: (name, value) => dispatch(saveChallengeInformation(name, value)),
  getChallengeInformation: () => dispatch(getChallengeInformation())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateChallengeFirst);
