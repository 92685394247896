/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Main, TitleContainer, ActivityDropdown, CustomMenuItem, FieldTitle } from '../../AdminTeamCreation/styles';
import { ChallengeHeading, Container, Button, ButtonContainer, FieldTitleNote, DropdownArrow, CustomLabel, Slider, EditorContainer } from './styles';
import StepsHeading from './stepsName';
import { createWellnessWeeks } from '../../../../mockData';
import { CheckBoxContainer } from '../../MatrixConversion/styles';
import { CustomCheckbox } from '../../ConnectDeviceModal/styles';
import Editor from '../../CKEditor';
import { connect } from 'react-redux';
import { saveChallengeInformation, getChallengeInformation } from '../../../redux/actions';
import { withTranslation } from 'react-i18next';

class CreateChallengeFour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 1,
      step: 4,
      selectWeeks: "",
      imageArrow: false,
      showExtraNote: false,
      showNote: ''
    }
  }

  componentDidMount() {
    const { getChallengeInformation, challengeData } = this.props;
    getChallengeInformation();
    this.setState({
      selectWeeks: challengeData.selectWeeks,
      showNote: challengeData.showNote,
      showExtraNote: challengeData.showExtraNote
    });
  }

  onSelectWeeks = (name, value) => {
    this.setState({
      [name]: value
    });
    this.props.saveChallengeInformation(name, value)
  };

  showNote = (status) => {
    this.setState({
      showExtraNote: status
    });
    this.props.saveChallengeInformation("showExtraNote", status)
  };

  onChangeDescription = (evt) => {
    const newContent = evt.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, '');
    trimmedString.length === 0 || trimmedString.length === 7 || trimmedString === '<p></p><p></p>' ? this.setState({ showNote: "" }) : this.setState({ showNote: newContent })
    this.props.saveChallengeInformation("showNote", newContent)
  };

  render() {
    const { step, selectWeeks, showExtraNote, showNote } = this.state;
    const { history } = this.props;
    return (
      <React.Fragment>
        <Layout marginBottom="0px">
          <Main>
            <ChallengeHeading>
              Create a Wellness Challenge
            </ChallengeHeading>
            <Container>
              <div className="stepsNameHighlight">
                <StepsHeading stepCount={step} />
              </div>
              <div className="form">
                <div className="heading">
                  <div className="step">4</div>
                  <div className="headingName">Create The Challenge</div>
                </div>
                <div className="formBody">
                  <TitleContainer fullWidth>
                    <FieldTitle>{"You have total 7 task, Let’s set the days require for each task."}</FieldTitle>
                    <FieldTitleNote background="1">Note : Keep average 3 day Buffer for each task. In this way, user can perform the task nicely.</FieldTitleNote>
                    <DropdownArrow alt='image' imageArrow={this.state.imageArrow} src={this.state.imageArrow ? "/images/teamDownArrow.svg" : "/images/dropdown_arrow.svg"} />
                    <ActivityDropdown
                      placeholder="Select Option"
                      title={selectWeeks ? selectWeeks : "Select Weeks"}
                      id="dropdown-recurring"
                      onClick={() => this.setState({ imageArrow: !this.state.imageArrow })}
                    >
                      {
                        createWellnessWeeks.map((item, index) => (
                          <CustomMenuItem
                            eventKey={index}
                            key={index}
                            onSelect={() => this.onSelectWeeks('selectWeeks', item['name'])}
                            active={selectWeeks == item['name']}
                          >
                            {item['name']}
                          </CustomMenuItem>
                        ))
                      }
                    </ActivityDropdown>
                  </TitleContainer>
                  <FieldTitle marginTop="25px">{"Would you like to add extra note?"} <img src={"/images/CompanyDashBoardV2/toolTipIcon.png"} /></FieldTitle>
                  <div className="checkbox">
                    <CheckBoxContainer checked={showExtraNote ? true : false}>
                      <div className="active-unit">
                        {"No"}
                      </div>
                      <div className="icon">
                        <CustomLabel className="switch">
                          <CustomCheckbox
                            type="checkbox"
                            checked={showExtraNote ? true : false}
                            onClick={showExtraNote ? () => this.showNote(false) : () => this.showNote(true)}
                          />
                          <Slider background={"rgba(156, 156, 156, .5)"} className="slider round" />
                        </CustomLabel>
                      </div>
                      <div className="inactive-unit">
                        {"Yes"}
                      </div>
                    </CheckBoxContainer>
                    {showExtraNote ? <div className="text">{showNote ? showNote.length : 0}/250 Words</div> : null}
                  </div>
                  {showExtraNote ? <EditorContainer>
                    <Editor content={showNote} onChange={this.onChangeDescription} />
                  </EditorContainer> : null}
                  {showExtraNote && showNote && showNote.length === 0 ? <FieldTitleNote>* Please right something in the note or off the Toggle Button if you don’t want to write.</FieldTitleNote> : null}
                </div>
              </div>
            </Container>
          </Main>
        </Layout>
        <ButtonContainer>
          <div className="wrapper">
            <Button color={'white'} textColor={'#9c9c9c'} border={'#9c9c9c'} width={"113px"} marginLeft="1" onClick={() => history.push('/company/challenges/create/step3')}>
              {this.props.t("Go Back")}
            </Button>
            <Button disabled={!selectWeeks || (showExtraNote && !showNote)} color="linear-gradient(90deg,rgb(0,111,188) -1.11%,rgb(127,67,255) 97.46%)" width="288px" onClick={() => history.push('/company/challenges/categories')} marginAuto="1">
              {this.props.t("Save & Create Challenge")}
            </Button>
          </div>
        </ButtonContainer>
      </React.Fragment>
    );
  }
}

CreateChallengeFour.propTypes = {
  history: PropTypes.object,
  challengeData: PropTypes.object,
  saveChallengeInformation: PropTypes.func,
  getChallengeInformation: PropTypes.func,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  challengeData: state.challengesAdmin.challengeData
});

const mapDispatchToProps = (dispatch) => ({
  saveChallengeInformation: (name, value) => dispatch(saveChallengeInformation(name, value)),
  getChallengeInformation: () => dispatch(getChallengeInformation())
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(CreateChallengeFour));
